.imageGallery {
}

.imageGallery :global(.slick-slide) {
  transition: transform 200ms ease-in-out;
  opacity: 0.25;
}

.imageGallery :global(.slick-slide):global(.slick-current) {
  transform: scale(1.2);
  z-index: 1000;
  position: relative;
  opacity: 1;
}

@media (min-width: 768px) {
  .imageGallery :global(.slick-slider) :global(.slick-dots) {
    bottom: 12px !important;
    right: 12px !important;
    text-align: right;
  }
}
