@keyframes guildPromotionFloatinCtaSlideIn {
  0% {
    translate: 0 -3rem;
    opacity: 0;
  }
  100% {
    translate: 0 0;
    opacity: 1;
  }
}

.slideIn {
  animation: guildPromotionFloatinCtaSlideIn 1s ease-out forwards 2s;
}
